@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Condensed:wght@400;700&family=Roboto+Slab:wght@400;700&display=swap');

html, body, #root {
  height: 100%;
  background-color: hsl(var(--b2));
  color: hsl(var(--bc));
}

body {
  font-family: 'Roboto', sans-serif;
}